.wallPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-image: url("../../images/assets/experiencecenter/front.jpg");
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  @media only screen and (max-width: 722px) {
    background-color: hsla(0, 0%, 0%, 0.3);
    background-position: center;
    background-repeat: no-repeat;
  }
}

.titleText{
  font-size: 4.125em;
  font-family: "Jeju Myeongjo", serif;
  font-weight: bold;
  color: #fff;
}

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: 1100px;
  min-height: 800px;

  .images{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49%;
    margin: 10px;
    &:hover {
      cursor: pointer;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    font-size: 18px;
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #ccc;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #555;
    }
  }

  .current-page {
    font-size: 18px;
    font-weight: bold;
    margin: 0 5px;
  }
}

// 이미지 확대
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  position: relative;
}

.modalImage {
  max-width: 100%;
  max-height: 100%;
}
.closeButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  outline: none;
}
