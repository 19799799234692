
body {
  overflow-x: hidden;
}

*, :after, :before {
  box-sizing: border-box;
}

* {
  height: auto;
}


.responsiveDisplay {
  @media only screen and (min-width: 722px) {
    display: flex;
  }
  @media only screen and (max-width: 722px) {
    display: none;
  }
}

.responsiveMobile {
  @media only screen and (min-width: 722px) {
    display: none;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
  }
}


.wallPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-image: url("../../../images/assets/experiencecenter/front.jpg");
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  @media only screen and (max-width: 722px) {
    background-color: hsla(0, 0%, 0%, 0.3);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.titleText{
  font-size: 4.125em;
  font-family: "Jeju Myeongjo", serif;
  font-weight: bold;
  color: #fff;
}

.wallPageItem {
  line-height: 1.5;
  @media only screen and (min-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    width: 460px;
    height: 460px;
    padding: 60px;;
    background-color: white;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40%;
    margin-bottom: 10%;
    background-color: white;
    word-break: keep-all;
    word-wrap: break-word;
  }

  .barIcon {
    @media only screen and (min-width: 722px) {
      display: flex;
      width: 24px;
      height: 4px;
      background-color: black;
      margin-top: 48px;
      margin-bottom: 48px;
    }
    @media only screen and (max-width: 722px) {
      display: flex;
      width: 24px;
      height: 4px;
      background-color: black;
      margin-top: 24px;
      margin-bottom: 24px;
    }

  }
}

.Introduce {

  @media only screen and (min-width: 722px) {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    background-color: #dbdbdb;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    padding: 5.6% 16.6%;
  }

  @media only screen and (max-width: 722px) {
    display: flex;
    width: 100%;
    height: auto;
    background-color: #dbdbdb;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 5.6%;
  }

}

.LineBreak {
  word-break: keep-all;
  word-wrap: break-word;
}

.InfoText {

  @media only screen and (min-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex: 1 0 50%;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: center;
    flex: 0 0 50%;
  }
}

.TechnologyInfoImg {
  @media only screen and (min-width: 722px) {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    flex: 1 1 50%;
    height: 100%;
  }

  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 80%;
    flex: 0 0 50%;

  }
}


.InfoImgCar {
  position: absolute;
  max-width: 50%;
  flex: 0 1 30%;
  z-index: -1;
  overflow: hidden;

  @media only screen and (min-width: 722px) {
    margin-top: 50px;
    margin-right: 50px;
  }
  @media only screen and (max-width: 880px) {
    max-height: 30%;
  }
  @media only screen and (max-width: 722px) {
    margin: 0;
    max-width: 80%;
    flex: 0 1 50%;
  }
}

.InfoImgPhone {
  position: absolute;
  max-width: 50%;
  flex: 0 1 30%;
  z-index: -1;
  overflow: hidden;
  @media only screen and (min-width: 722px) {
    margin-left: 10%;
  }
  @media only screen and (max-width: 722px) {
    margin: 0;
    max-width: 50%;
    flex: 0 1 50%;
  }
}


.ComMainSection {
  background-color: black;

  @media only screen and (min-width: 722px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-left: 5.6%;
    padding-right: 5.6%;
    padding-bottom: 10%;
  }
  @media only screen and (max-width: 722px) {
    width: 100%;
    height: auto;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 8%;
  }
}

.CompanyItemTitle {
  @media only screen and (min-width: 722px) {
    width: 100%;
    padding-top: 10%;
    padding-Bottom: 6%;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    flex: 0 0 80%;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 100%;
    height: 340px;
  }
}

.CompanyItemBox {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: row wrap;
  background-color: black;
  @media only screen and (max-width: 722px) {
    justify-content: center;
    width: 100%;
  }
}

.CompanyItem {
  background-color: #373737;
  line-height: 1.5;
  @media only screen and (min-width: 722px) {
    display: flex;
    align-items: center;
    padding-left: 5%;
    padding-top: 4%;
    flex-flow: row nowrap;
    height: 340px;
    flex: 0 2 47%;
    margin-right: 10px;
    margin-bottom: 10px;

  }
  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 340px;
    padding: 9%;
    margin-bottom: 12px;
  }

  &:hover {
    background-color: #d5ff3d;
    cursor: pointer;
    color: black;
  }

  h2 {
    position: relative;
    color: #fff;
    text-decoration: none;
    padding: 0;
    margin: 0;
    display: inline-block;
  }

  &:hover h2 {
    position: relative;
    color: black;
    text-decoration: none;
    padding: 0;
    margin: 0;
    display: inline-block;
  }

  .textColor {
    color: #A2A2A2;
    @media only screen and (max-width: 722px) {
      word-break: keep-all;
      word-wrap: break-word;
    }
  }

  &:hover .textColor {
    color: black;
  }
}

.CompanyItemFirst {
  @media only screen and (min-width: 722px) {
    display: flex;
    line-height: 1.5;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    flex: 0 2 47%;
    height: 340px;
    padding-left: 5%;
    padding-top: 4%;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #d5ff3d;
  }
  @media only screen and (max-width: 722px) {
    background-color: #373737;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 340px;
    padding: 9%;
    margin-bottom: 12px;
  }

  :hover > & {
    background-color: #373737;

    h2 {
      color: white;
    }

    .textColor {
      color: #A2A2A2;
    }
  }

  &:hover {
    background-color: #d5ff3d;
    cursor: pointer;
  }


  h2 {
    position: relative;
    color: black;
    text-decoration: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    @media only screen and (max-width: 722px) {
      color: white;
    }
  }

  &:hover h2 {
    position: relative;
    color: black;
    text-decoration: none;
    padding: 0;
    margin: 0;
    display: inline-block;
  }

  .textColor {
    color: black;
    @media only screen and (max-width: 722px) {
      word-break: keep-all;
      word-wrap: break-word;
      color: #A2A2A2;
    }
  }

  &:hover .textColor {
    color: black;
  }
}

.CompanyItemContent {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 70%;
  flex: 0 1 auto;
  height: 100%;

  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
}

.CompanyItemContentImg {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  flex: 1 0 30%;
  height: 100%;

  @media only screen and (max-width: 722px) {
    display: flex;
    justify-content: flex-start;
  }
}

.imgStyle {
  height: 100px;
  @media only screen and (max-width: 1024px) {
    height: 70px;
  }
  @media only screen and (max-width: 722px) {
    height: 90px;
  }
}

.CoreInfo {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  width: 100%;
  height: 900px;
  max-width: 100%;
  line-height: 1.5;
  background-attachment: scroll;
  //background-image: url("/images/assets/PC-BG.jpg"); // road
  background-repeat: no-repeat;
  background-size: cover;
  background-color: hsla(0, 0%, 0%, 0.1);
  background-blend-mode: overlay;
  z-index: -1;
  color: white;
  padding-left: 5.6%;
  padding-right: 5.6%;
  @media only screen and (max-width: 722px) {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    padding: 10% 8% 15%;
  }
}

.LogoStyle {
  @media only screen and (min-width: 722px) {
    width: 228px;
    box-sizing: content-box;
    z-index: 1;
    bottom: 10%;
    margin-bottom: 13%;
  }
  @media only screen and (max-width: 722px) {
    display: flex;

    padding-bottom: 10%;
    padding-top: 10%;
  }
}

.CoreItems {
  display: flex;
  width: 100%;
  align-items: center;
  flex-flow: row nowrap;

  @media only screen and (max-width: 1280px) {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
  }

  @media only screen and (max-width: 722px) {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
  }
}

.CoreItem {
  @media only screen and (min-width: 722px) {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    flex: 0 1 25%;
  }

  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    height: 300px;
    flex: 0 0 50%;
  }
}
.plusStyle {
  height: 25px;
  margin: 30px;
  @media only screen and (max-width: 722px) {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 20px;
  }
}

.byePlus{
  height: 25px;
  margin: 30px;

  @media only screen and (max-width: 1280px) {
    display: none;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 20px;
  }

}

.CoreBlock{
  display: flex;
  width:100%;
  align-items: center;
  margin-bottom: 0;
  @media only screen and (max-width: 1280px) {
    margin-bottom: 50px;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-bottom: 0;
  }
}

.CoreItemContent{
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.CoreItemTitle{
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap; // 줄바꿈 방지
  color: black;
}
.CoreItemText{
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  word-break: keep-all;
  word-wrap: break-word;
  color: #A2A2A2;

}

.backGround {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #dbdbdb;
  @media only screen and (min-width: 722px) {
    table-layout: fixed;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media only screen and (max-width: 722px) {
    padding-top: 80%;
  }
}

.downloadMain {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  background-position: bottom;
  //background-image : url("/images/assets/PC-BG.jpeg");
  background-attachment: scroll;
  background-size: contain;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  @media only screen and (max-width: 722px) {
    background-size: cover;
  }
}


//////kakao Map/////////////
.serviceUseGuide {
  width: 100%;
  display: flex;
  background-color: #cde2de;

  @media only screen and (min-width: 722px) {
    min-height: 570px;
  }
  @media only screen and (max-width: 722px) {
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
  }
}

.hoursOfUse {
  flex: 0 1 50%;
  background-color: black;
  color: white;
  flex-flow: column nowrap;
  word-break: keep-all;
  padding: 5.6%;
  h2 {
    margin: 0;
  }

  img {
    height: 32px;
  }

  @media only screen and (max-width: 722px) {
    font-size: 90%;

    img {
      height: 26px;
    }
  }
}

.googleMap {
  width: 100%;
  height: 720px;
  flex: 1 0 50%;
}
