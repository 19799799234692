
body {
  overflow-x: hidden;
}

*, :after, :before {
  box-sizing: border-box;
}

* {
  height: auto;
}


.responsiveDisplay {
  @media only screen and (min-width: 722px) {
    display: flex;
  }
  @media only screen and (max-width: 722px) {
    display: none;
  }
}

.responsiveMobile {
  @media only screen and (min-width: 722px) {
    display: none;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
  }
}


.wallPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-image: url("../../images/assets/doban/bg.jpeg");
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  @media only screen and (max-width: 722px) {
    background-color: hsla(0, 0%, 0%, 0.3);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.titleText{
  font-size: 4.125em;
  font-family: "Jeju Myeongjo", serif;
  font-weight: bold;
  color: #fff;
}

.content {
  @media only screen and (min-width: 722px) {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    padding: 5.6% 16.6%;
  }

  @media only screen and (max-width: 722px) {
    display: flex;
    width: 100%;
    height: auto;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 5.6%;
  }

}

.LineBreak {
  word-break: keep-all;
  word-wrap: break-word;
}

.InfoText {

  @media only screen and (min-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex: 1 0 50%;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: center;
    flex: 0 0 50%;
  }
}

.menuBar {
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 20px;

  .title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .list {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 20px;
    padding: 0;
    @media only screen and (max-width: 722px) {
      flex-flow: row wrap;
    }
    .menubutton {
      width: 165px;
      height: 45px;
      text-align: center;
      cursor: pointer;
      padding: 10px 20px;
      margin: 0 10px 10px;
      border: 1px solid black;
      font-weight: bold;
      color: gray;

      &:hover {
        background-color: #e2e2e2;
      }
      &.active {
        background-color: rgba(108, 137, 80);
        color: white;
      }
    }
  }
}
.menuList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menuItem {
  margin-bottom: 1rem;
}

.menuItemButton {
  display: block;
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  text-align: left;
  cursor: pointer;


  &:hover {
    background-color: #ddd;
  }
}

.menuItemDescription {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  @media only screen and (max-width: 722px) {
    flex-flow: column nowrap;
  }

  .menuItemDescriptionImage {
    width: 50%;
    margin-right: 2rem;
    @media only screen and (max-width: 722px) {
      width: 80%;
     margin: 0;
    }
  }

  .menuItemDescriptionText {
    @media only screen and (min-width: 722px) {
      width: 50%;
      margin: 2rem;
    }
    @media only screen and (max-width: 722px) {
      width: 100%;
    }

    h2 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
  }
}
.menubox{
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-left: 16.6%;
  padding-right: 16.6%;
  @media only screen and (max-width: 722px) {
    padding-left: 5.6%;
    padding-right: 5.6%;
  }
}

.menuinfo{
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(226,226,215);
  padding-bottom: 50px;

  h3{
    color:rgb(68,88,61)
  }
  .menus{
    padding: 3.6%;
  }
  text{
    color: rgb(65,87,61);
  }

  .menu_name{
    color:rgb(68,88,61);
    font-weight: bold;
    margin-top: 10px;
  }
}
