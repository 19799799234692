.header {

  @media only screen and (min-width: 722px) {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    #background-color: #333;
    transition: top 0.4s;

    &--hidden {
      top: -50px;
      height: 0;
    }

    .header_nav {
      display: flex;
      width: 100%;
      flex-flow: row nowrap;
      padding: 1rem 2rem;
      color: #fff;

      a {
        color: black;
        text-decoration: none;
      }
    }

    .header-title{
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
      position: absolute;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      text-align: center;
      color: white;
      width: auto;
      z-index: 5;
      left: 50%;
      top:50px;
      transform: translate(-50%, -50%);
      font-size: 30px;
      font-weight: bold;
      transition: top 0.4s;

      &--hidden {
        top: -50px;
        height: 0;
      }
    }
  }


  @media only screen and (max-width: 722px) {
    display: none;
  }
}
