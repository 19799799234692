body {
  overflow-x: hidden;
}

*, :after, :before {
  box-sizing: border-box;
}

* {
  height: auto;
}

.main {
  display: flex;
  width: 100%;
  min-height: 1080px;
  flex-flow: column nowrap;
  background-attachment: scroll;
  background-image: url("../images/assets/front/front_main.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  @media only screen and (min-width: 722px) {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  @media only screen and (max-width: 722px) {
    justify-content: center;
    height: 100%; // 휴대폰에서 잘 안보이는 부분이 있음.
    background-color: hsla(0, 0%, 0%, 0.3);
    //background-image: url("/images/assets/PC-BG.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 80px;
  }
}

.title{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  color: white;
  @media only screen and (min-width: 722px) {
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 722px) {
    margin-bottom: 50px;
  }
  .t01{
    font-size: 34px;
    font-weight: normal;
    border-bottom: 1px solid white;
    margin-bottom: 5px;
  }
  .t02{
    font-size: 34px;
    font-weight: bold;
    border-bottom: 3px solid white;
    margin-bottom: 5px;
  }
  .t03{
    font-size: 24px;
    font-style: oblique;
    font-weight: lighter;
  }
}
.videoWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 18.25%; /* 16:9 */
  padding-top: 160px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 722px) {
    width: 854px;
    height: 480px;
  }
}

.container{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  @media only screen and (min-width: 722px) {
    flex-flow: row nowrap;
  }
  @media only screen and (max-width: 722px) {
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
  }
}

@mixin round{
  height: 300px;
  width: 300px;
  border-radius: 50%;
  color: snow;

  @media only screen and (max-width: 1000px) {
    height: 250px;
    width: 250px;
  }
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;
  @media only screen and (max-width: 722px) {
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    margin-bottom: 10px;
  }
}
.r01{
  @include round;
  background-color: rgb(186,172,40);
}

.r02{
  @include round;
  background-color: rgb(108,37,39);
}

.r03{
  @include round;
  background-color: rgb(95,156,186);
}


.footer{
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: end;
}
