.faq {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  background-attachment: scroll;
  background-image: url("../../images/assets/faq/faq_bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  padding-right: 5.6%;
  padding-left: 5.6%;
  @media only screen and (min-width: 722px) {
    min-height: 1080px;
  }
  @media only screen and (max-width: 722px) {
    align-items: center;
    justify-content: center;
    min-height: 720px;
    background-color: hsla(0, 0%, 0%, 0.3);
    background-position: center;
    background-repeat: no-repeat;
  }

}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 60px;
  color: white;
}

.questions {
  width: 100%;
  margin: 40px auto;
  text-align: center;
  list-style: none;
  padding: 0;
  margin-top: 100px;
}

.question {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.question:hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
}

.active {
  background-color: red;
}

.questionTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.questionAnswer {
  padding: 40px;
  background-color: #fafafa;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.menuBar {
  padding: 10px;
  background-color: #f2f2f2;
  margin-bottom: 10px;

  .title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .menuList {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    .menuItem {
      cursor: pointer;
      padding: 10px 20px;
      margin: 0 10px;
      &:hover {
        background-color: #e2e2e2;
      }
    }
  }
}
.menuButton {
  background-color: #eaeaea;
  border-radius: 5px;
  color: #333;
  margin: 2px;
}

.active {
  background-color: #333;
  color: #fff;
}

.paginationcontainer {
  display: flex;
  width: 300px;
  height: 300px;
  justify-content: center;
  flex-flow: row nowrap;
  margin-top: 2rem;

  .pagination {
    display: flex;
    list-style: none;

    .page-item {
      margin: 0 0.5rem;
      &.active {
        .page-link {
          color: #fff;
          background-color: #007bff;
          border-color: #007bff;
        }
      }

      .page-link {
        color: #007bff;
        background-color: transparent;
        border: 1px solid #007bff;
        padding: 0.5rem 0.75rem;
        border-radius: 0.25rem;

        &:hover {
          color: #fff;
          background-color: #007bff;
          border-color: #007bff;
          text-decoration: none;
        }
      }

      &.active-link {
        display: none;
      }
    }
  }
}
