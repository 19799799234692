.wallPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-image: url("../../images/assets/wallpage_bg.jpeg");
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  @media only screen and (max-width: 722px) {
    background-color: hsla(0, 0%, 0%, 0.3);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.titleText{
  font-size: 4.125em;
  font-family: "Jeju Myeongjo", serif;
  font-weight: bold;
  color: #fff;
}

.title {
  word-break: keep-all;
  word-wrap: break-word;
  margin-bottom: 50px;
}

.imgStyle {
  height: 100px;
  @media only screen and (max-width: 1024px) {
    height: 70px;
  }
  @media only screen and (max-width: 722px) {
    height: 90px;
  }
}

.board {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  @media only screen and (min-width: 722px) {
    table-layout: fixed;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 5.6%;
    padding-right: 5.6%;
  }
  @media only screen and (max-width: 722px) {
    flex-flow: column nowrap;
    padding-top: 50px;
  }

  .group{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  .image{
    width: 270px;
    height: 170px;
  }

  .Rule{
    width: 75%;
    display: flex;
    flex-flow: column nowrap;
    padding-top: 35px;
    padding-bottom: 35px;
    .head{
      font-size: 20px;
      color: #333;
      float: left;
      box-sizing: border-box;
      font-weight: bold;
    }
    .texts{
      flex: 1;
      font-size: 15px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .table{
    margin-top: 10px;
    text-align: center;
    border-collapse: collapse;
    th{
      border: 1px solid black;
    }
    td{
      padding: 10px;
      word-break: keep-all;
      border: 1px solid black;
    }
  }
}
