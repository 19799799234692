
body {
  overflow-x: hidden;
}

*, :after, :before {
  box-sizing: border-box;
}

* {
  height: auto;
}


.responsiveDisplay {
  @media only screen and (min-width: 722px) {
    display: flex;
  }
  @media only screen and (max-width: 722px) {
    display: none;
  }
}

.responsiveMobile {
  @media only screen and (min-width: 722px) {
    display: none;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
  }
}


.wallPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 700px;
  background-image: url("../../images/assets/doban/store02.jpg");
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  @media only screen and (max-width: 722px) {
    background-color: hsla(0, 0%, 0%, 0.3);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.titleText{
  font-size: 4.125em;
  font-family: "Jeju Myeongjo", serif;
  font-weight: bold;
  color: #fff;
}

.LineBreak {
  word-break: keep-all;
  word-wrap: break-word;
  text-decoration: none;
}

.call {
  word-break: keep-all;
  word-wrap: break-word;
  text-decoration: none;
  color: white;
}

.imgStyle {
  height: 100px;
  @media only screen and (max-width: 1024px) {
    height: 70px;
  }
  @media only screen and (max-width: 722px) {
    height: 90px;
  }
}
.reserve_row{
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  @media only screen and (min-width: 1024px) {
    padding-left: 16.6%;padding-right: 16.6%;
  }
  @media only screen and (max-width: 722px) {
    flex-flow: column nowrap;
  }
}

.reserve_main {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-flow: column nowrap;
  align-items: center;

  @media only screen and (min-width: 722px) {
    table-layout: fixed;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.round{
  height: 300px;
  width: 300px;
  border-radius: 50%;
  margin-top: 30px;
  color: snow;
  text-align: center;
  background-color:rgba(108, 137, 80);
  @media only screen and (max-width: 1000px) {
    height: 250px;
    width: 250px;

  }
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 722px) {
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    margin-bottom: 10px;
  }
}


//////kakao Map/////////////
.serviceUseGuide {
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  margin-top: 50px;

  @media only screen and (min-width: 722px) {
    min-height: 570px;
  }
  @media only screen and (max-width: 722px) {
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
  }
}

.hoursOfUse {
  flex: 0 1 50%;
  background-color: black;
  color: white;
  flex-flow: column nowrap;
  word-break: keep-all;
  padding: 5.6%;
  h2 {
    margin: 0;
  }

  img {
    height: 32px;
  }

  @media only screen and (max-width: 722px) {
    font-size: 90%;

    img {
      height: 26px;
    }
  }
}

.googleMap {
  width: 100%;
  height: 720px;
  flex: 1 0 50%;
}
