.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}
.modal-main {
    position:fixed;
    background: white;
    /*padding-bottom: 20px;*/
    /*padding-top: 20px;*/
    top:0%;
    left:0%;
    //transform: translate(-50%,-50%);
    z-index: 2;
    @media only screen and (min-width: 722px) {

    }
    @media only screen and (max-width: 722px) {
        display: flex;
        flex-flow: column nowrap;
        top: 5%;
        left: 5%;
        transform: translate(0,0);
    }
}

.modal-header{
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
}

.modal-textarea{
    width:100%;
    height: 100%;
    overflow-x:hidden;
    overflow-y: scroll;
}

.display-block {
    display: block;
    @media only screen and (max-width: 722px) {
        display: none;
    }
}

.display-none {
    display: none;

}
.modal-footer{
    width: 100%;
    height: 2rem;
    border: 0 solid;
    border-top-width: 1px;
    margin-top: 5px;
}

.footer-content{
    display: flex;
    width: fit-content;
    box-sizing: border-box;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
}
.footer-checkbox{
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin: 5px;
    border: black solid 1px;
    background-color: white;
}

.word-sty {
    width:100%;
    max-height: 100%;
    overflow-y: scroll;
    padding-top: 10px;
    text-align: center;
    white-space: pre-line;
    word-break: keep-all;
    word-wrap: break-word;

}
.modal-button{
    padding: 2px;
}

.close-container {
    width: 60px;
    height: 60px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close {
    width: 40px;
    height: 40px;
    position: relative;
}

.close:before,
.close:after {
    position: absolute;
    content: " ";
    height: 40px;
    width: 3px;
    background-color: #000;
}

.close:before {
    transform: rotate(45deg);
}

.close:after {
    transform: rotate(-45deg);
}
