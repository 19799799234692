.wallPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-image: url("../../images/assets/wallpage_bg.jpeg");
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  @media only screen and (max-width: 722px) {
    background-color: hsla(0, 0%, 0%, 0.3);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.titleText{
  font-size: 4.125em;
  font-family: "Jeju Myeongjo", serif;
  font-weight: bold;
  color: #fff;
}
.Introduce {

  @media only screen and (min-width: 722px) {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    background-color: #dbdbdb;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    padding: 5.6%;
  }

  @media only screen and (max-width: 722px) {
    display: flex;
    width: 100%;
    height: auto;
    background-color: #dbdbdb;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 5.6%;
  }

}

.LineBreak {
  word-break: keep-all;
  word-wrap: break-word;
}

.InfoText {

  @media only screen and (min-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex: 1 0 50%;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: center;
    flex: 0 0 50%;
  }
}
