.footerMain {
  line-height: 1.5;
  position: relative;
  @media only screen and (min-width: 722px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    height: 240px;
    background-color: black;
    left: 0;
    bottom: 0;
    padding: 50px 10.6%;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    background-color: black;
    left: 0;
    bottom: 0;
    padding: 20px;
  }
}


.logoDiv {
  @media only screen and (min-width: 722px) {
    display: flex;
    justify-content: flex-end;
    padding-right: 5%;
    height: 100%;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    padding-bottom: 5%;
  }

  .logoSize {
    @media only screen and (min-width: 722px) {
      height: 120px;
    }
    @media only screen and (max-width: 722px) {
      height: 120px;
    }
  }
}

.textContents {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 30%;
  height: 100%;
}

.responsiveTerms {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.terms {
  @media only screen and (min-width: 722px) {
    display: flex;
    flex: 1 0 auto;
    flex-flow: row nowrap;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    height: 100%;
    flex-flow: column nowrap;
  }

  #tag1 {
    margin-right: 10px;
    @media only screen and (max-width: 722px) {
      padding-bottom: 30px;
    }
  }
}

.shareIcons {
  @media only screen and (min-width: 722px) {
    display: none;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
  }
}


.FooterVisibility {
  @media only screen and (min-width: 722px) {
    display: flex;
    height: 100%;
    flex-flow: column nowrap;
    padding-right: 10%;
  }
  @media only screen and (max-width: 722px) {
    display: none;
  }
}

.LineBreak {
  word-break: keep-all;
  word-wrap: break-word;
}

.language {
  display: flex;
  justify-content: flex-end;
}
