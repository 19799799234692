.menuMain{
  display: flex;
  flex-flow: row nowrap;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;

  top:0;
  z-index: 2;
  background-color: #6C8950AA; // hex 끝에 AA 투명도
  border-bottom: 2px solid #D5FF3D;
  color: white;
  font-weight: bold;

  @media only screen and (min-width: 722px) {
    margin-top: 100px;
    padding-left: 5.6%;
    padding-right: 5.6%;
  }
}

.hidden{
  @media only screen and (min-width: 722px) {
    transition: top 0.4s;
    top: -100px;
  }
}

.menuLogo{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  img{

    width: 60px;
  }
  @media only screen and (max-width: 722px) {
    display: none;
  }
}

.mobileResponse {
  @media only screen and (min-width: 722px) {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-around;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: left;
    white-space: nowrap;
  }
  @media only screen and (max-width: 390px) {
    a{
      text-align: center;
      font-size: 14px;
    }
  }
}

.hamburgerOn{
  @media only screen and (min-width: 722px) {
    display: none;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    z-index: 2;
  }
}
.menu {
  position: relative;
  justify-content: center;
  align-items: center;
}

.menuItem {
  cursor: pointer;
  margin-right: 10px;
  /* Delay mouse leave event */
  &:hover {
    & ~ .subMenu {
      display: block;
    }
  }
}

.subMenu {
  width: 180px;
  position: absolute;
  top: calc(100%); /* Add a small gap between main menu item and submenu */
  left: 0;
  padding: 10px;

  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #ccc;
  display: none;

  /* Remove submenu on mouse leave */
  &:hover {
    display: block;
  }
}

.subMenuItem {
  padding: 5px;
  cursor: pointer;
  color: gray;
  &:hover {
    color: white;
    font-weight: bold;
  }
}
