.wallPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-image: url("../../../images/assets/wallpage_bg.jpeg");
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  @media only screen and (max-width: 722px) {
    background-color: hsla(0, 0%, 0%, 0.3);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.titleText{
  font-size: 4.125em;
  font-family: "Jeju Myeongjo", serif;
  font-weight: bold;
  color: #fff;
}

.head {
  word-break: keep-all;
  word-wrap: break-word;
  margin-bottom: 50px;
  margin-top: 50px;
}

.photo{
  width: 50%;
}

.imgStyle {
  height: 100px;
  @media only screen and (max-width: 1024px) {
    height: 70px;
  }
  @media only screen and (max-width: 722px) {
    height: 90px;
  }
}

.backGround {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  @media only screen and (min-width: 722px) {
    table-layout: fixed;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media only screen and (max-width: 722px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .history{
    width: 75%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 35px;

    .year{
      font-size: 20px;
      color: #333;
      width: 16.34%;
      float: left;
      box-sizing: border-box;
      font-weight: bold;
    }
    .milestones{
      flex: 1;
      margin-left: 50px;
      font-size: 15px;
    }
  }
}
.premiumService {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  background-color: white;

  @media only screen and (min-width: 722px) {
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 1320px;
    padding: 5% 16.6%;
  }
  @media only screen and (max-width: 722px) {
    height: 100%;
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 10rem;
    display: flex;
    flex-flow: column nowrap;
  }
}

.premiumTitle {
  text-align: center;
  padding-bottom: 3rem;
  padding-top: 5rem;
}

.premiumContents {
  justify-content: center;
  text-align: center;
  @media only screen and (min-width: 722px) {
    display: flex;
    width: 100%;
    min-height: 580px;
    flex-flow: column nowrap;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
  }
  .facilityInfo{
    padding: 0px 0 10px 0px;
    font-size: 27px;
    color: #0a3711;
    letter-spacing: -0.75px;
  }
}

.premiumContentsItem {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px 10px 6%;
  @media only screen and (min-width: 722px) {
    height: 100%;
    flex: 1 0 33%;
  }
  @media only screen and (max-width: 722px) {
    height: 100%;
  }

  img {
    @media only screen and (min-width: 722px) {
      max-width: 80%;
    }
    @media only screen and (max-width: 722px) {
      width: 100%;
    }
  }

  .premiumContentsText {
    justify-content: flex-end;
    display: flex;
    flex-flow: column nowrap;
    height: 30%;
  }
}

.premiumFooter {
  display: flex;
  width: 100%;
  height: 300px;
  flex-flow: column nowrap;
  background-position: bottom;
  //background-image: url('/images/home-footer.png');
  background-attachment: scroll;
  background-size: contain;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  @media only screen and (max-width: 722px) {
    background-size: cover;
  }
}
