.wallPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-image: url("../../images/assets/experiencecenter/front.jpg");
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  @media only screen and (max-width: 722px) {
    background-color: hsla(0, 0%, 0%, 0.3);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.titleText{
  font-size: 4.125em;
  font-family: "Jeju Myeongjo", serif;
  font-weight: bold;
  color: #fff;
}
.noticeList {
  max-width: 1200px;
  margin: 0 auto;

  .title {
    font-size: 2rem;
    margin-bottom: 5rem;
    text-align: center;
  }

  .listinfo{
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-left: 5.6%;
    padding-right: 5.6%;
    .infobox{
      font-weight: bold;
    }
  }
  .list {
    list-style: none;
    margin: 5.6%;

    .item {
      display: flex;
      width: 100%;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid #ddd;

      .link {
        display: block;
        border-radius: 5px;
        transition: background-color 0.3s;
        text-decoration: none;

        &:hover {
          background-color: #e3e3e3;
        }

        .noticeTitle {
          font-size: 1.2rem;
        }

        .noticeContent {
          font-size: 1rem;
          margin: 0;
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .page-item {
      &.active {
        button {
          background-color: #007bff;
          border-color: #007bff;
        }
      }

      button {
        display: inline-block;
        padding: 0.5rem 0.75rem;
        margin-right: 0.25rem;
        color: #007bff;
        background-color: #fff;
        border: 1px solid #007bff;
        border-radius: 5px;
        transition: all 0.3s;

        &:hover {
          background-color: #007bff;
          color: #fff;
        }
      }
    }
  }
}
