.wallPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-image: url("../../images/assets/experiencecenter/regular_bg.jpg");
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.3);
  background-blend-mode: overlay;
  @media only screen and (max-width: 722px) {
    background-color: hsla(0, 0%, 0%, 0.3);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.titleText{
  font-size: 4.125em;
  font-family: "Jeju Myeongjo", serif;
  font-weight: bold;
  color: #fff;
}

.noticeDetail {
  width: 100%;
  height: 100%;

  .content{

  }
  .content_head{
    display: flex;
    justify-content: space-between;
    margin-left: 5.6%;
    margin-right: 5.6%;
  }

  .board{
    min-height: 800px;
    display: flex;
    margin-left: 5.6%;
    margin-right: 5.6%;
    margin-bottom: 50px;
    flex-flow: column nowrap;
    border-top: 1px solid rgba(108, 137, 80);
  }

  .title {
    margin-top: 50px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    color: #333;
    @media (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  .body {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    white-space: pre-wrap;


    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .goback{
    position: absolute;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    padding: 20px;
    background-color: rgba(108, 137, 80);
    color: white;
    font-size: 17px;
    font-weight: bold;
  }
}
