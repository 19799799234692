@media only screen and (max-width: 722px) {
  body {
    overflow-x: hidden;
  }
  html {
    overflow-x: hidden;
  }
}

.responsiveDisplay {
  @media only screen and (min-width: 722px) {
    display: flex;
  }
  @media only screen and (max-width: 722px) {
    display: none;
  }
}

.homeMain {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  @media only screen and (min-width: 722px) {
    //padding-top: 70px;
  }
}

.homeMainVisual {
  display: flex;
  width: 100%;

  background-repeat: no-repeat;

  @media only screen and (min-width: 722px) {
    background-image: url('../../images/assets/doban/bg.jpeg');
    background-position: top;
    background-size: cover;
    padding-left: 16.6%;
    padding-right: 16.6%;
    padding-bottom: 18%;
    height: 100%;
  }

  @media only screen and (max-width: 722px) {
    background-image: url('/images/assets/main.jpeg');
    background-position: top;
    background-size: cover;
    justify-content: center;
    align-items: center;
    padding-left: 8%;
    padding-right: 8%;
    height: 480px;
  }
}

.visualContent {
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
  line-height: 1;
  @media only screen and (min-width: 722px) {
    padding-top: 14%;
  }
  @media only screen and (max-width: 722px) {
    width: 100%;
    height: 100%;
    padding-bottom: 20%;
    justify-content: flex-end;
  }
}

.visualText {
  display: flex;
  flex-flow: column nowrap;


  span h1 {
    background-color: #d5ff3d;
    display: inline-flex;
    @media only screen and (min-width: 722px) {
      white-space: nowrap;
      font-size: 5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 2rem;
      padding-right: 6rem;
    }

    @media only screen and (max-width: 722px) {
      font-size: 2rem;
      padding: 10px 20px;
      margin: 0;
      white-space: nowrap;
    }

  }

  span h2 {
    margin: 0;
    font-weight: normal;
    display: inline-flex;
    background-color: #d5ff3d;
    padding: 10px 20px;
    @media only screen and (min-width: 722px) {
      font-size: 2.5rem;
      white-space: nowrap;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 2rem;
      padding-right: 3rem;
    }
    @media only screen and (max-width: 722px) {
      font-size: 1.5rem;
    }
  }

  .h2title{
    margin: 0;
    font-weight: normal;
    display: inline-flex;
    background-color: #d5ff3d;
    padding: 10px 20px;
    @media only screen and (min-width: 722px) {
      font-size: 2.5rem;
      white-space: nowrap;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 2rem;
      padding-right: 3rem;
    }
    @media only screen and (max-width: 722px) {
      width: 160px;
      font-size: 1.5rem;
    }
  }
}

.download {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  @media only screen and (max-width: 722px) {
    display: none;
  }
}

.downloadMobile {
  @media only screen and (min-width: 722px) {
    display: none
  }
  @media only screen and (max-width: 722px) {
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    height: 96px;
    width: 100%;
    border-radius: 10px;
    background-color: black;
  }

}

.serviceIntroduction {
  background-color: #cde2de;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  @media only screen and (min-width: 722px) {

    align-items: center;
    justify-content: center;
    padding-left: 16.6%;
    padding-right: 16.6%;
    padding-bottom: 10%;
  }
  @media only screen and (max-width: 722px) {
    justify-content: center;
    align-items: center;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 8%;
  }
}

.serviceTitle {
  @media only screen and (min-width: 722px) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    padding-Bottom: 6%;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    flex: 0 0 80%;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 100%;
    height: 340px;
    padding-top: 15%;
    padding-bottom: 15%
  }
}

.serviceFloor {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  @media only screen and (max-width: 722px) {
    flex-flow: column nowrap;
  }
}


.serviceItem {

  background-color: #fff;
  line-height: 1.5;

  @media only screen and (min-width: 722px) {
    display: flex;
    align-items: center;
    padding-left: 5%;
    padding-top: 4%;
    flex-flow: row nowrap;
    min-height: 340px;
    flex: 0 1 50%;
    max-width: 50%;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 380px;
    padding: 9%;
    margin-bottom: 12px;
  }

}

.serviceItemText {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  flex: 0 0 50%;
  word-break: keep-all;

  .lineAni {
    @media only screen and (max-width: 1000px) {
      font-size: 0.8rem;
    }
    white-space: nowrap;
  }

  h2 {
    z-index: 1;
  }

  @media only screen and (min-width: 722px) {
    .lineAni {
      height: 3rem;
    }
    .lineAni > h2 {
      position: absolute;
      color: #000;
      text-decoration: none;
      padding: 0;
      margin: 0;
      display: inline-block;
    }

    .lineAni > h2 {
      &:hover {
        cursor: pointer;
      }

      &:before {
        z-index: -1;
        content: "";
        position: absolute;
        width: 10px;
        height: 15px;
        bottom: 10%;
        left: 0;
        background-color: #D5FF3D;
        visibility: hidden;
        transition: all 0.5s ease-in-out;
      }

      :hover > &:before {
        display: inline-block;
        visibility: visible;
        width: 100%;
        transition-duration: 0.3s;
      }
    }
  }

  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    .lineAni {
      display: inline-block;
      width: min-content;
      height: 1.2rem;
      font-size: 1rem;
      white-space: nowrap;
      position: relative;
      align-items: flex-end;
      background-color: #d5ff3d;
    }
    h2 {
      display: inline-flex;
      position: relative;
      margin: 0;
      bottom: 15px;
    }
  }

}

.CompanyLink {
  display: flex;
  width: fit-content;
  height: fit-content;
  margin-top: 10%;
  padding: 1rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  background-color: #e2e2e2;
  white-space: nowrap;
}
.mobileImg {
  @media only screen and (min-width: 722px) {
    display: none;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.serviceImg {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 722px) {
    flex: 0 0 40%;
    padding-bottom: 10%;
    width: 100%;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    align-items: flex-end;
    width: 100%;

  }

  img {
    @media only screen and (min-width: 722px) {
      width: 100%;
    }
    @media only screen and (max-width: 722px) {
      height: 80%;
    }

  }
}

.how_to_use {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  max-width: 1920px;
  z-index: 1;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 722px) {
    padding-left: 16.6%;
    padding-right: 16.6%;
  }
}

.instructionsTitle {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 30px;
  height: 200px;
  font-weight: bold;
  @media only screen and (max-width: 722px) {
    word-break: keep-all;
    word-wrap: break-word;
    text-align: center;
  }
}


.chapter__items {
  @media only screen and (min-width: 722px) {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    margin-left: 66px;
    z-index: 1;
    min-width: 50%;
    order: 2;

  }
  @media only screen and (max-width: 722px) {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    z-index: 1;
    width: 100%;
    order: 2;
  }

}

.chapter__item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  width: 100%;
  max-width: 100%;
}

.context {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  word-break: keep-all;
  word-wrap: break-word;
  width: 100%;
}

.context_reverse {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  word-break: keep-all;
  word-wrap: break-word;
  width: 100%;
}

.context_text {
  @media only screen and (min-width: 1024px) {
    flex-basis: 50%;
    width: 50%;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 100vh;
  }
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;
  justify-content: center;
}

.context_text {

  padding-top: 55px;
  padding-bottom: 15px;
  position: relative;
  z-index: 1;
}

.context_img{
  @media only screen and (min-width: 1024px) {
    flex-basis: 50%;
    width: 50%;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 100vh;
  }
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;
  justify-content: center;
}


.text_sizeReply {
  word-break: keep-all;
  word-wrap: break-word;
  @media only screen and (min-width: 1024px) {
    max-width: none;
    width: calc(100% - 5.83333vw);
    margin-left: 5.83333vw;
  }
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 88.33333%;

  h1 {
    width: 100%;
  }

  //글자 크기에 대한 것
  @media only screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 1.25;
    h1 {
      font-size: 30px;
      line-height: 1.1;
      margin-bottom: 0;
    }
  }
  @media only screen and (min-width: 1024px) {
    h1 {
      font-size: 30px;
      line-height: 1.1;
    }

  }
  @media only screen and (min-width: 1280px) {
    h1 {
      font-size: 40px;
      line-height: 1.13333;
    }
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;

    h1 {
      width: auto;
      font-size: 24px;
    }
    text-align: center;
  }
}

.video_media {
  display: block;
  margin-left: -40px;
  margin-right: -40px;
  position: relative;
  overflow: hidden;
  padding-top: 100%;
  height: 100%;

  @media only screen and (min-width: 1024px) {
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;

    video {
      left: 0;
      transform: translateY(-50%);
    }
  }
  @media only screen and (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-top: 85%;
  }

  video {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    max-width: 100%;
    width: auto;
    z-index: 2;
    transform: translate(-50%, -50%);
    object-fit: contain;
    vertical-align: middle;
  }
}

/////////////////

.chapter__navigation {
  @media only screen and (min-width: 722px) {
    display: flex;
    flex-direction: column;
    flex-basis: 66px;
    justify-content: center;
    position: sticky;
    top: 30%;
    height: 100vh;
    order: 1;
    width: 66px;
    max-width: 66px;
    z-index: 2;
    padding-top: 50px;
  }
  @media only screen and (max-width: 722px) {
    display: none;
    flex-direction: column;
    flex-basis: 66px;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 30%;
    height: 100vh;
    order: 1;
    width: 66px;
    max-width: 66px;
    z-index: 2;
  }
}

.chapter__navigation_inner {
  position: sticky;
  width: 100%;
  height: 200px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}


.nav_item {
  width: 25px;
  height : 25px;
  font-size: 14px;
  line-height: 1;
  position: relative;
  text-decoration: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3 {
    position: relative;

    margin: 0;
    padding: 0;
    color: white;
  }

  a {
    color: inherit;
    background-color: transparent;
  }
}

.nav_item:before {
  border: 2px solid black;
  background-color: black;
  border-radius: 50%;
  content: "";
  display: block;
  height: 25px;
  width: 25px;
  position: absolute;
  margin: 0;
  padding: 0;
}

[title*='check'] {
  &.nav_item:before {
    width: 34px;
    height: 34px;
    border-color: black !important;
    background-color: #D5FF3D !important;
  }

  &.nav_item h3 {
    font-size: 1.3rem;
    color: black !important;
  }

}

//////Google Map/////////////
.serviceUseGuide {
  width: 100%;
  height: 800px;
  display: flex;
  background-color: #cde2de;

  @media only screen and (min-width: 722px) {
    padding: 5% 16.6%;
  }
  @media only screen and (max-width: 722px) {
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
  }
}

.hoursOfUse {
  flex: 0 1 30%;
  height: 100%;
  background-color: black;
  color: white;
  flex-flow: column nowrap;
  padding: 40px;
  word-break: keep-all;

  h2 {
    margin: 0;
  }

  img {
    height: 32px;
    padding-right: 5px;
  }

  @media only screen and (max-width: 722px) {
    font-size: 90%;

    img {
      height: 26px;
    }
  }
}

.googleMap {
  flex: 1 0 50%;
}

.premiumService {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  background-color: #f2f2f2;

  @media only screen and (min-width: 722px) {
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 1320px;
    padding: 5% 16.6%;
  }
  @media only screen and (max-width: 722px) {
    height: 100%;
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 10rem;
    display: flex;
    flex-flow: column nowrap;
  }
}

.premiumTitle {
  text-align: center;
  padding-bottom: 3rem;
  padding-top: 5rem;
}

.premiumContents {
  justify-content: center;
  text-align: center;
  @media only screen and (min-width: 722px) {
    display: flex;
    width: 100%;
    min-height: 580px;
    flex-flow: column nowrap;
  }
  @media only screen and (max-width: 722px) {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
  }
  .facilityInfo{
    padding: 0px 0 10px 0px;
    font-size: 27px;
    color: #0a3711;
    letter-spacing: -0.75px;
  }
}

.premiumContentsItem {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px 10px 6%;
  @media only screen and (min-width: 722px) {
    height: 100%;
    flex: 1 0 33%;
  }
  @media only screen and (max-width: 722px) {
    width: 100%;
    height: 100%;
  }

  img {
    @media only screen and (min-width: 722px) {
      width: 80%;
    }
    @media only screen and (max-width: 722px) {
      width: 100%;
    }
  }

  .premiumContentsText {
    justify-content: flex-end;
    display: flex;
    flex-flow: column nowrap;
    height: 30%;
  }
}

.premiumFooter {
  display: flex;
  width: 100%;
  height: 300px;
  flex-flow: column nowrap;
  background-position: bottom;
  //background-image: url('/images/home-footer.png');
  background-attachment: scroll;
  background-size: contain;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  @media only screen and (max-width: 722px) {
    background-size: cover;
  }
}

